$image-cta__cta-container-width\@medium     : 380px;
$image-cta__cta-container-width\@large      : 480px;
$image-cta__cta-container-width\@extra-large: 624px;
$image-cta__cta-container-padding\@medium   : $spacer--large;
$image-cta__cta-container-padding\@large    : 72px;
$image-cta__cta-container-left\@large       : 72px;

.image-cta {
    position: relative;
    display: block;

    &--slider {
        width: 100%;

        .image-cta {
            &__content {
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                max-width: $max-content-width;
                height: 100%;
                transform: translateX(-50%);
            }

            &__cta-container {
                position: absolute;
                top: 50%;
                left: $spacer--large;
                grid-gap: 0;
                width: 232px;
                background: none;
                transform: translateY(-50%);

                @include mq($screen-m) {
                    width: $image-cta__cta-container-width\@medium;
                    padding: $image-cta__cta-container-padding\@medium;
                }

                @include mq($screen-l) {
                    left: $image-cta__cta-container-left\@large;
                    width: $image-cta__cta-container-width\@large;
                    padding: $image-cta__cta-container-padding\@large;
                }

                @include mq($screen-xl) {
                    width: $image-cta__cta-container-width\@extra-large;
                }
            }

            &__heading {
                line-height: 1.2;

                @include mq($screen-xl) {
                    font-size: $font-size-super-extra-extra-large;
                }
            }

            &__text {
                margin-bottom: $spacer;

                @include mq($screen-xl) {
                    margin-bottom: $spacer--large;
                    font-size: $font-size-large;
                }
            }
        }
    }

    &__cta-container {
        display: grid;
        grid-gap: $spacer--semi-medium;
        padding: $spacer--large 0;
        background: $white;

        @include mq($screen-m) {
            position: absolute;
            top: 50%;
            left: $spacer--large;
            width: $image-cta__cta-container-width\@medium;
            padding: $image-cta__cta-container-padding\@medium;
            transform: translateY(-50%);
        }

        @include mq($screen-l) {
            left: $image-cta__cta-container-left\@large;
            width: $image-cta__cta-container-width\@large;
            padding: $image-cta__cta-container-padding\@large;
        }
    }
}
