.how-it-works {
    .steps-list {
        margin: $spacer--medium auto 0;

        @include mq($screen-m) {
            max-width: 496px;
            margin: $spacer 0 0 $spacer--extra-large;
        }
    }
}
