.product-view {
    &__wrapper {
        min-width: 100%;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        column-gap: 5px;
        flex-direction: column;

        @include mq($screen-m) {
            align-items: center;
            flex-direction: row;
        }
    }

    &__stock-status {
        margin: 0;

        &--out-of-stock {
            border-color: $purple;
            pointer-events: none;
        }
    }

    &__description {
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-m) {
            border-bottom: $border-base;
        }

        &--no-border {
            border-bottom: none;
        }
    }

    &__price {
        .price__value {
            font-size: $font-size-base;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }
        }
    }

    &__gallery {
        @include mq($screen-xl) {
            padding-right: $spacer--large;
        }
    }

    &__main-actions {
        .quantity-update__button {
            &:before {
                position: initial;
                display: contents;
            }
        }
    }

    &__add-to-cart,
    &__update-cart {
        &.tocart {
            display: inline-flex;

            .button__text {
                display: block;
            }
        }
    }
}
