$mega-menu__item-border-width          : 4px;

$mega-menu__thumbnail-size             : 200px;
$mega-menu__thumbnail-size\@extra-large: 224px;

.mega-menu {
    @include mq($mega-menu__breakpoint) {
        z-index: calc(#{$z-index-normal} - 1);
    }

    &__list {
        border-top: $border-base;
    }

    &__item {
        &:hover,
        &:active,
        .focus-visible {
            .mega-menu__link {
                border-bottom-color: $orange;
            }
        }

        &--tiles {
            > .mega-menu__inner-list {
                padding: 0;
            }
        }
    }

    &__inner-item--level1 {
        &.list__item {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__link {
        border: $mega-menu__item-border-width $border-style-base transparent;
        border-width: $mega-menu__item-border-width 0;
        transition: $transition-base;
    }

    &__tiles-wrapper {
        display: flex;
        justify-content: space-evenly;
    }

    &__tile {
        display: flex;
        flex-direction: column;
        width: $mega-menu__thumbnail-size;
        padding: $spacer--semi-large 0;

        @include darken-layout-hover('.lazyload-wrapper');

        @include mq($screen-xl) {
            width: $mega-menu__thumbnail-size\@extra-large;
        }

        &--wide {
            @include mq($screen-xl) {
                width: 384px;
            }
        }

        &--divider {
            width: $border-width-base;
            padding: 0;
            background-color: $border-color-base;

            @include mq($screen-xl) {
                width: $border-width-base;
            }
        }
    }

    &__tile-thumbnail {
        height: $mega-menu__thumbnail-size;
        object-fit: cover;

        @include mq($screen-xl) {
            height: $mega-menu__thumbnail-size\@extra-large;
        }
    }

    &__tile-heading {
        align-self: flex-start;
        width: 100%;
        margin: $spacer--medium 0 0;
        font-weight: $font-weight-bold;
    }

    &__tile-heading,
    &__tile-text {
        padding: 0 $spacer;
        font-size: $font-size-medium;
    }
}
