.link-three-items {
    &__col {
        margin-bottom: $spacer;
        text-align: center;
    }

    &__show-all-container {
        margin-top: $spacer--semi-large;
        text-align: center;
    }

    &__show-all-link {
        color: $gray-darker;
        font-weight: $font-weight-bold;
        text-decoration: none;
    }
}
