$instagram-feed__minimal-image-height : 150px; // to avoid large content jump

.instagram-feed {
    width: 100%;
    max-width: $container__max-width;
    margin: 0 auto;
    padding: 0;

    &__photo {
        margin-bottom: $spacer--medium;
    }

    &__photos {
        min-height: $instagram-feed__minimal-image-height;
    }

    .mpinstagramfeed-photo {
        margin: 0 $spacer $spacer--medium;
        cursor: pointer;

        a {
            display: inline;
            position: inherit;
            z-index: 100;
        }

        @include darken-layout-hover('.lazyload-wrapper');
    }

    .mpinstagramfeed-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: initial;
        transform: initial;
    }
}
