.card-style-bottom {
    &__link {
        display: grid;
        grid-gap: $spacer--medium;
    }

    &__heading {
        font-size: $font-size-base;
        text-align: center;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }
    }
}
