$button__background                 : $orange;
$button__background-hover           : $color-primary;
$button__text-transform             : none;
$button__text-color                 : $color-primary;
$button__text-color-hover           : $white;

$button__color-hover--secondary     : $color-primary;
$button__border--secondary          : 2px $border-style-base $orange;
$button__border-hover--secondary    : $orange;
$button__background-hover--secondary: $orange;

$button__icon-fill--add-to          : $color-primary;

$button__background-disabled        : $color-disabled;

@import 'button';
@import 'cpf-button';
