.card-style-text-on {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    text-align: left;

    @include darken-layout-hover('.lazyload-wrapper');

    .lazyload-wrapper {
        width: 100%;
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__heading {
        margin-bottom: 0;
    }


    &__subtitle {
        position: relative;
        display: inline-flex;
        padding: 0 $spacer--extra-small;
        font-weight: $font-weight-bold;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 8px;
            background-color: $orange;
            content: '';
        }
    }

    &__subtitle-text {
        z-index: 3;
    }

    &__content {
        position: absolute;
        padding: $spacer--medium $spacer--medium $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer--large $spacer--large $spacer--medium;
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        margin-bottom: $spacer--medium;
        outline: $border-width-base $border-style-base $purple-light;

        @include mq($screen-m) {
            align-items: flex-end;
            justify-content: flex-start;
            margin-bottom: 0;
        }
    }
}
