.image-stack {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);

    &__item {
        &--top {
            grid-row: 1 / span 7;
            grid-column: 1 / span 10;
        }

        &--bottom {
            grid-row: 6 / span 7;
            grid-column: 3 / span 10;
        }
    }
}
