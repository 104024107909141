.product-grid-item {
    &--slider-item {
        border: none;

        .product-grid-item__image-wrapper {
            border: $border-width-base $border-style-base $purple-light;
        }
    }

    &__name-link {
        font-weight: $product-grid-item__name-font-weight;
    }

    &__details-bottom {
        flex-flow: column wrap;
    }

    &__price {
        @include mq($screen-m) {
            width: 100%;
        }
        .price__value:not(.price__value--between) {
            display: inline;
        }
    }

    &__actions {
        @include mq($screen-m) {
            width: 100%;
        }
    }

    &__secondary-action {
        flex-basis: $button__min-size;
    }

    &__primary-action {
        flex-basis: calc(100% - #{$button__min-size});
    }
}
