.list {
    &__label {
        &::after {
            position: absolute;
        }
    }

    &__value {
        word-break: break-word;
        overflow-wrap: anywhere;
    }

    &__link-ellipsis {
        display: inline-block;
        width: 240px;
        margin-bottom: -$spacer;
        overflow: hidden;
        line-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
