.steps-list {
    &__item {
        margin-bottom: $spacer;
        text-align: left;
    }

    &__heading {
        position: relative;
        display: flex;
        margin-bottom: $spacer--semi-medium;

        &::after {
            position: absolute;
            bottom: -8px;
            left: 0;
            display: block;
            width: 24px;
            height: 8px;
            background: $orange;
            content: ' ';
        }
    }

    &__description {
        font-size: $font-size-small;

        @include mq($screen-m) {
            font-size: $font-size-base;
        }
    }

    &__number {
        margin-right: $spacer--extra-small;
    }
}
