$headings-font-weight                     : $font-weight-extra-bold;
$headings-text-transform                  : none;

$heading-font-size--page\@screen-m        : $font-size-super-extra-extra-large;

$heading-font-size--fourth-level          : $font-size-large;
$heading-font-size--fifth-level           : $font-size-large;
$heading-font-size--sixth-level           : $font-size-large;

$headings-text-transform--page            : none;

@import 'heading';
@import 'cpf-heading';
