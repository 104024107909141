$amgift-card__content-button-color--hover   : #fff !default;
$amgift-card__content-button-border--hover  : $button__border--secondary !default;
$amgift-card__image-padding                 : 0 $spacer--medium 0 0 !default;
$amgift-card__container-margin              : 0 0 $spacer--medium 0 !default;

.amgift-card {
    &__content-button {
        // hiver state for <input type="button"> element
        @at-root input#{&} {
            &:hover,
            &.focus-visible {
                color: $amgift-card__content-button-color--hover;
            }
        }
    }

    .amcard-image {
        padding: $amgift-card__image-padding;
    }

    .amcard-title {
        @extend .label;
    }

    .amcard-form-container.-interior {
        margin: $amgift-card__container-margin;
    }
}
