.slider {
    &--full-width {
        .slider__navigation {
            left: 50%;
            max-width: $max-content-width;
            transform: translateX(-50%);
        }
    }

    &__nav-button {
        background: $gray-lighter;
        border: none;
    }
}
