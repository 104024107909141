.info-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    background-color: $gray-lighter;
    transition: $transition-base;

    &--secondary {
        .info-card__content {
            padding: $spacer--semi-medium $spacer--medium $spacer--small;

            @include mq($screen-m) {
                padding: $spacer--large $spacer--semi-medium $spacer--medium;
            }
        }
    }

    &__heading {
        margin-bottom: 0;
    }

    &__content {
        margin-top: auto;
        padding: $spacer--large $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer--large $spacer--extra-large;
        }
    }

    &__divider {
        margin-bottom: $spacer;
    }

    &__description {
        margin-bottom: 0;
    }

    &__button {
        margin-top: auto;
    }

    &--blog {
        .info-card__heading {
            margin-bottom: $spacer--medium;
        }

        .info-card__content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &:hover,
    &:focus {
        background-color: $gray-darker;

        .info-card__heading,
        .info-card__description {
            color: $gray-lighter;
        }

        .info-card__image {
            opacity: 0.4;
        }
    }
}
