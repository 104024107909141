$section__max-width--narrow: 1104px;

.section {
    @include mq($screen-m) {
        margin-bottom: 88px;
    }

    &--secondary {
        @include mq($screen-m) {
            margin: $section__margin--secondary\@medium;
        }
    }

    &--full-width {
        position: relative;
        right: 50%;
        left: 50%;
        width: calc(100vw - #{$spacer--extra-small});
        margin-right: -50vw;
        margin-left: -50vw;

        .section__heading,
        .section__content {
            max-width: $section__max-width--narrow;
            margin-right: $spacer--semi-large;
            margin-left: $spacer--semi-large;

            @include mq($screen-l) {
                margin-right: $spacer--extra-large;
                margin-left: $spacer--extra-large;
            }

            @include mq($section__max-width--narrow) {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &--darker-bg {
        padding: $spacer--semi-large 0 $spacer--large;
        background-color: $gray-light;
    }

    &--narrow {
        max-width: $section__max-width--narrow;
        margin-right: auto;
        margin-left: auto;
    }

    &__heading {
        flex-direction: column;
        align-items: stretch;
        text-align: center;

        @include mq($screen-m) {
            margin: 0 0 $spacer--extra-large;
        }
    }

    &--background {
        margin-bottom: 0;
        .section__heading {
            padding: 50px 0;
            margin-bottom: 0;
        }
    }
}
