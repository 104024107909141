.divider {
    display: flex;
    height: 8px;
    background-color: $gray-lightest;

    &--transparent {
        background-color: transparent;
    }

    &::before {
        width: 30%;
        height: 100%;
        background-color: $orange;
        content: '';
    }

    &--fixed {
        &::before {
            width: 88px;
        }
    }

    &--monocolor {
        &::before {
            display: none;
        }
    }

    &--small {
        height: 4px;
    }
}
