.quantity-update {
    div.mage-error {
        position: absolute;
    }
}
.shadow__heading {
    display: none;
    & + ul {
        > li.product-step {
            border-top: none;
        }
    }
}
@media (min-width: 1200px) {
    .product-view__gallery {
        .fotorama {
            .fotorama__stage {
                display: table-cell;
            }
        }
    }
}