$usp-info__icon-size: 64px;

.usp-info {
    @include mq($screen-m) {
        padding-left: $spacer--large;
    }

    &__list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        list-style: none;

        @include mq($screen-l) {
            flex-direction: row;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: $spacer;

        @include mq($screen-l) {
            display: block;
        }
    }

    &__description {
        color: $purple;
    }

    &__icon {
        width: $usp-info__icon-size;
        min-width: $usp-info__icon-size;
        height: $usp-info__icon-size;
        margin-right: $spacer--medium;

        @include mq($screen-l) {
            margin-right: 0;
        }
    }
}
