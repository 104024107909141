.newsletter {
    @include mq($screen-m) {
        align-items: center;
        height: 100%;
    }

    &__controls {
        @include mq($screen-l) {
            margin-right: 56px;
        }

        @include mq($screen-xl) {
            max-width: none;
        }
    }

    &__field {
        color: $gray-lighter;
        background: rgba(246, 246, 250, 0.1); /* stylelint-disable-line */
        border: none;

        &:hover,
        &.focus-visible {
            outline-color: $gray-lighter;
        }

        &::placeholder {
            color: $gray-lighter;
        }
    }

    &__button {
        background: $orange;
        border: none;

        &:hover,
        &.focus-visible {
            color: $white;
            background-color: $color-primary;
        }

        &::before {
            background-color: $color-primary;
        }
    }

    &__checkbox {
        display: none;
    }
}
