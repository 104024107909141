.cart-list-item {
    &__info {
        width: calc(100% - #{$cart-list-item__image-width});
    }

    &__label,
    .list__label {
        font-weight: $font-weight-bold;
    }

    &__name {
        margin-bottom: $spacer--medium;
        color: $color-primary;
    }
}
