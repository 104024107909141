.steps-cards {
    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($screen-s) {
            flex-direction: row;

            &:nth-child(2n + 2) {
                .step-card__arrow {
                    display: none;
                }
            }
        }

        @include mq($screen-m) {
            &:nth-child(2n + 2) {
                .step-card__arrow--horizontal {
                    display: block;
                }
            }
        }

        &:last-child {
            .step-card__arrow {
                display: none;
            }
        }
    }
}
