$mega-menu__background-color     : transparent;
$mega-menu__border               : $border-base;
$mega-menu__border-width         : 0 0 $border-width-base;

$mega-menu__link-text-transform  : none;
$mega-menu__link-font-weight     : $font-weight-bold;
$mega-menu__link-color           : $color-primary;
$mega-menu__link-color--hover    : $white;
$mega-menu__link-background-color: transparent;
$mega-menu__link-color--hover    : $color-primary;
$mega-menu__link-padding         : 0 $spacer--extra-small;

$mega-menu__inner-link-before-bg : $gray-lighter;
$mega-menu__inner-link-min-height: 40px;

@import 'mega-menu';
@import 'cpf-mega-menu';
