$step-card__heading-font-size        : $font-size-base;
$step-card__heading-font-size\@medium: $font-size-medium;

$step-card__number-size              : 56px;

$step-card__arrow-color              : $purple;
$step-card__arrow-size               : 24px;
$step-card__arrow-bottom-offset      : #{($step-card__number-size - $step-card__arrow-size) / 2};
$step-card__arrow-right-offset       : -20px;

.step-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    background-color: $white;

    &__image {
        margin-bottom: $spacer--medium;
    }

    &__heading {
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        font-size: $step-card__heading-font-size;
        text-align: center;

        @include font-padding($step-card__heading-font-size, 48);

        @include mq($screen-m) {
            font-size: $step-card__heading-font-size\@medium;

            @include font-padding($step-card__heading-font-size\@medium, 48);
        }
    }

    &__number {
        display: flex;
        flex-basis: $step-card__number-size;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: $step-card__number-size;
        height: $step-card__number-size;
        font-weight: 700;
        font-size: $font-size-large;
        border: $border-base;
        border-radius: 50%;
    }

    &__icon {
        width: 100%;
        height: auto;
    }

    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mq($screen-s) {
            flex-direction: row;
        }
    }

    &__arrow {
        width: $step-card__arrow-size;
        height: $step-card__arrow-size;
        margin-top: $spacer--large;
        fill: $step-card__arrow-color;

        &--horizontal {
            display: none;
        }

        &--vertical {
            margin-top: $spacer--medium;
        }

        @include mq($screen-s) {
            &--vertical {
                display: none;
            }

            &--horizontal {
                position: absolute;
                right: $step-card__arrow-right-offset;
                bottom: $step-card__arrow-bottom-offset;
                z-index: 2;
                display: block;
                transform: rotate(-90deg);
            }
        }
    }
}
