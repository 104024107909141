$button__loader-icon-size: 30px;

.button {
    &__loader-icon {
        position: absolute;
        inset: 0;
        width: $button__loader-icon-size;
        height: $button__loader-icon-size;
        margin: auto;
        border: 4px solid $gray-lightest;
        border-top: 4px solid $orange;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    &--secondary {
        &::before {
            z-index: $z-index-lowest;
            background-color: $button__background-hover--secondary;
        }
    }

    &--add-to {
        &:hover,
        &.focus-visible {
            .button__icon {
                fill: $white;
            }
        }
    }

    &:disabled,
    &[disabled] {
        border-color: $border-color-base;
    }

    &--blank {
        justify-content: flex-start;
        min-height: unset;
        padding: 0;
        font-weight: $font-weight-normal;
        text-align: left;
        background: none;
        transform: none;

        &:hover,
        &:focus,
        &.focus-visible {
            background: none;

            &::before,
            &::after {
                display: none;
            }
        }
    }


    &--loading {
        position: relative;
        overflow: hidden;

        &::before {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: $button__background-disabled;
            transform: none;
            content: '';
        }

        &:disabled,
        &[disabled] {
            &:hover,
            &.focus-visible {
                &::before {
                    display: block;
                }
            }
        }
    }
}
