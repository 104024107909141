.link {
    &--no-text-decoration {
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    &--invert {
        text-decoration: underline;
    }
}
