.heading {
    &--fourth-level,
    &--fifth-level,
    &--sixth-level {
        font-weight: $font-weight-bold;
    }

    &--light {
        font-weight: normal;
    }
}
