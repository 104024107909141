.tab {
    &__title {
        @include mq($screen-l) {
            border-bottom-color: $gray-lightest;
            border-bottom-width: 8px;
        }

        &--active {
            @include mq($screen-l) {
                border-color: $orange;
            }
        }
    }

    &--secondary {
        padding-bottom: 0;

        .tab__title {
            flex: unset;
            width: auto;
            min-width: 200px;
        }
    }
}
