$product-grid-item__details-margin        : $spacer--semi-medium 0 $spacer--medium;
$product-grid-item__name-font-size        : $font-size-medium;
$product-grid-item__name-font-size\@medium: $font-size-large;
$product-grid-item__name-font-weight      : $font-weight-extra-bold;
$product-grid-item__name-height           : auto;
$product-grid-item__name-height\@small    : auto;
$product-grid-item__name-margin           : 0 0 $spacer--small;
$product-grid-item__details-padding\@xl   : 0 $spacer--medium;
$product-gird-item__primary-margin        : 0 $spacer 0 0;

@import 'product-grid-item';
@import 'cpf-product-grid-item';
