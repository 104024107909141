// Custom Picture Frames variables

// Colors
$orange                           : #ff9101;
$purple-light                     : #c2c1df;
$purple                           : #615060;
$purple-dark                      : #251924;
$red                              : #e32926;
$blue                             : #1a57ff;

// Grayscale colors
$gray-darkest                     : #333;
$gray-darker                      : #393243;
$gray-dark                        : #4c4555;
$gray                             : #b6aeb5;
$gray-light                       : #f9f9f9;
$gray-lighter                     : #f3f1f3;
$gray-lightest                    : #f6f6fa;

// Semantic color scheme
$color-primary                    : $purple-dark;
$color-disabled                   : $gray;

// Focus inline
$color-focus-inline-dark          : $gray-darkest;
$bg-focus-inline-dark             : $orange;

// Typography
$font-family-sans-serif           : 'Montserrat', sans-serif;
$font-family-secondary            : $font-family-sans-serif;
$font-family-base                 : $font-family-sans-serif;

$font-size-super-extra-extra-large: 40px;

$font-weight-medium               : 700;
$font-weight-extra-bold           : 900;
