.link-card {
    flex-direction: column;
    padding: $spacer--large;
    word-break: break-word;
    background: $gray-light;

    &.link {
        &:hover {
            .link-card__divider {
                &::before {
                    background-color: $gray-darkest;
                    transition: $transition-base;
                }
            }
        }
    }

    &__icon {
        width: 80px;
        height: 80px;
    }

    &__title {
        font-size: $font-size-extra-large;
    }

    &__divider {
        margin-bottom: $spacer--medium;
    }

    &--secondary {
        padding: 0 $spacer--medium 0;
    }

    &--secondary,
    &--col-1 {
        background: $white;

        .link-card {
            &__icon {
                width: 120px;
                height: 120px;
            }

            &__divider {
                display: none;
            }
        }
    }

    &--col-1 {
        text-align: center;
        border: $border-width-base $border-style-base $orange;

        .link-card__icon {
            margin: 0 auto;
        }
    }
}
