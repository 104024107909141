.footer {
    &__scroll-top {
        &:focus {
            background-color: $footer__scroll-top-bg;
        }
    }

    &__social-list {
        .list__icon-link {
            background: none;
            border-color: $white;
        }

        .footer__social-icon {
            fill: $white;
        }
    }

    &__social-handler {
        @include mq($screen-l) {
            &::before {
                background: $gray-lighter;
            }
        }
    }

    &__links {
        .dropdown-list {
            &__label {
                &:hover,
                &.focus-visible {
                    color: $orange;
                }
            }

            &__list {
                justify-content: space-between;
            }

            &__item {
                @include mq($screen-m) {
                    width: 25%;
                }
            }
        }

        .list__item {
            @include mq($screen-m) {
                padding-right: $spacer--medium;
            }

            &:first-child {
                padding-top: $spacer--small;
            }
        }
    }

    &__link {
        &:hover,
        &.focus-visible {
            color: $orange;
            text-decoration: underline;
        }

        &--copyright {
            text-decoration: underline;
        }
    }

    &__badges {
        padding-top: $spacer--large;
        padding-left: 0;

        @include mq($screen-l) {
            padding-left: $spacer--medium;
        }
    }

    &__badges-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: -$spacer--medium;

        img {
            width: 60px;
            margin: 0 $spacer--medium $spacer--medium 0;
        }

        .lazyload-wrapper,
        img {
            background: none;
        }
    }

    &__copyright {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    &__bottom-links {
        @include mq($screen-l) {
            position: absolute;
            top: $spacer--medium;
            left: 0;
        }
    }
}
