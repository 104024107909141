.link-cards-grid {
    display: grid;
    grid-gap: $spacer--medium;
    grid-template-columns: 1fr;

    @include mq($screen-s) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq($screen-l) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &--col-1 {
        @include mq($screen-s) {
            grid-template-columns: 1fr;
        }

        @include mq($screen-l) {
            grid-template-columns: 1fr;
        }
    }

    &--col-2 {
        @include mq($screen-l) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &--col-4 {
        @include mq($screen-l) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
