.article-item {
    &__content {
        img {
            height: auto;
        }

        &--truncate {
            max-height: calc(#{$article-item__content-text-line-height} * 5em);
            overflow: hidden;
        }
    }

    &__title {
        padding: $spacer 0;
    }
}
