$product-view__max-width\@large                  : none;
$product-view__padding\@xl                       : $spacer--extra-large 0 0 0;
$product-view__wrapper-max-width                 : none;
$product-view__wrapper-grid-template-rows\@medium: auto 1fr;
$product-view__column-gap\@medium                : $spacer--medium;
$product-view__column-gap\@large                 : $spacer--large;
$product-view__column-gap\@xl                    : $spacer--large;
$product-view__desc-order                        : 0;
$product-view__title-font-size\@large            : $font-size-extra-large;
$product-view__title-margin                      : 0 0 $spacer;
$product-view__price-display                     : block;
$product-view__price-padding                     : 0 0 $spacer--medium;
$product-view__tabs-margin                       : $spacer--medium auto 0;
$product-view__tabs-sticky-max-width             : $max-content-width;

@import 'product-view';
@import 'cpf-product-view';
