$footer__background-color                                  : $purple;
$footer__border-width                                      : 0;

$footer__scroll-top-top                                    : -$spacer--semi-medium;
$footer__scroll-top-bg                                     : $orange;
$footer__scroll-top-icon-fill                              : $color-primary;

$footer__newsletter-padding\@large                         : 0 0 0 $spacer--extra-small;
$footer__newsletter-border                                 : $border-width-base $border-style-base $gray-lighter;
$footer__newsletter-border-width                           : 0 0 $border-width-base;

$footer__social-handler-padding\@large                     : 56px;

$footer__links-label-background                            : $purple;
$footer__links-label-color                                 : $white;

$footer__links-dropdown-list-margin                        : 0;
$footer__links-dropdown-list-padding\@large                : $spacer--large 0 0 $spacer--extra-small;
$footer__links-dropdown-list-padding\@extra-large          : $spacer--large 0 0 $spacer--extra-small;
$footer__links-dropdown-list-item-width-first-child\@medium: 100%;
$footer__links-dropdown-background                         : $purple;
$footer__links-dropdown-list-border                        : $border-width-base $border-style-base $gray-lighter;
$footer__links-dropdown-list-border-width                  : 0 0 $border-width-base;
$footer__links-dropdown-list-item-border                   : $border-width-base $border-style-base $gray-lighter;
$footer__links-dropdown-list-item-width-first-child\@medium: 25%;
$footer__links-list-item-min-width\@medium                 : 100%;

$footer__link-color                                        : $gray-lighter;
$footer__link-font-size                                    : $font-size-base;

$footer__links-dropdown-icon-color                         : $gray-lighter;
$footer__links-dropdown-icon-color-open                    : $gray-lighter;
$footer__links-dropdown-icon-color-hover                   : $orange;

$footer__social-list-title-color                           : $white;
$footer__social-list-title-font-size                       : $font-size-base;

$footer__bottom-padding\@large                             : 0;
$footer__copywrite-padding                                 : $spacer--medium 0;
$footer__copywrite-padding\@medium                         : $spacer--semi-medium 0;
$footer__copywrite-color                                   : $gray-lighter;
$footer__copywrite-font-size                               : $font-size-base;

@import 'footer';
@import 'cpf-footer';
