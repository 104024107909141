@font-face {
    font-weight: 300;
    font-family: Montserrat;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/Montserrat-Light.woff2') format('woff2'), url('../fonts/Montserrat-Light.woff') format('woff'), url('../fonts/Montserrat-Light.ttf') format('ttf');
}

@font-face {
    font-weight: normal;
    font-family: Montserrat;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'), url('../fonts/Montserrat-Regular.woff') format('woff'), url('../fonts/montserrat-regular.svg') format('svg');
}

@font-face {
    font-weight: 700;
    font-family: Montserrat;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'), url('../fonts/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat-Bold.svg') format('svg');
}

@font-face {
    font-weight: 900;
    font-family: Montserrat;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/Montserrat-Black.woff2') format('woff2'), url('../fonts/Montserrat-Black.woff') format('woff'), url('../fonts/Montserrat-Black.svg') format('svg');
}

@font-face {
    font-weight: 300;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/RobotoCondensed-Light.woff2') format('woff2'), url('../fonts/RobotoCondensed-Light.woff') format('woff'), url('../fonts/RobotoCondensed-Light.ttf') format('ttf');
}

@font-face {
    font-weight: normal;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'), url('../fonts/RobotoCondensed-Regular.woff') format('woff'), url('../fonts/RobotoCondensed-Regular.ttf') format('ttf');
}

@font-face {
    font-weight: 700;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'), url('../fonts/RobotoCondensed-Bold.woff') format('woff'), url('../fonts/RobotoCondensed-Bold.ttf') format('ttf');
}

@font-face {
    font-weight: normal;
    font-family: 'Dancing Script';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/DancingScript-Regular.woff2') format('woff2'), url('../fonts/DancingScript-Regular.woff') format('woff'), url('../fonts/DancingScript-Regular.ttf') format('ttf');
}

@font-face {
    font-weight: 600;
    font-family: 'Dancing Script';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/DancingScript-SemiBold.woff2') format('woff2'), url('../fonts/DancingScript-SemiBold.woff') format('woff'), url('../fonts/DancingScript-SemiBold.ttf') format('ttf');
}

@font-face {
    font-weight: 700;
    font-family: 'Dancing Script';
    font-style: normal;
    font-display: swap;
    src: url('../fonts/DancingScript-Bold.woff2') format('woff2'), url('../fonts/DancingScript-Bold.woff') format('woff'), url('../fonts/DancingScript-Bold.ttf') format('ttf');
}
