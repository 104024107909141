@mixin font-padding(
    $font-size,
    $height,
    $right: 0,
    $left: 0,
    $line-height: $font-line-height, // if not defined global setting is applied
    $mode: round
) {
    $font-size: $font-size;
    $vertical: $height - round($font-size * $line-height);

    @if $mode == ceil {
        $vertical: $height - ceil($font-size * $line-height);
    } @else if $mode == floor {
        $vertical: $height - floor($font-size * $line-height);
    }


    @if $vertical > 0 {
        $vertical: $vertical / 2;
    } @else {
        $vertical: 0;
    }


    @if $left == $right {
        padding: $vertical $left;
    } @else {
        padding: $vertical $right $vertical $left;
    }


    @if $line-height != $font-line-height {
        line-height: $line-height;
    }
}
