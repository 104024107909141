.add-to-cart {
    padding-top: $spacer;

    @include mq($screen-m) {
        padding-top: 0;
    }

    &__title {
        & > .heading {
            font-weight: $font-weight-normal;
        }
    }

    .price__value {
        font-size: $font-size-base;
    }

    &__button-more {
        display: none;
    }

    &__image {
        width: 56px;
        min-width: 56px;
        margin: auto $spacer--medium auto 0;
    }
}
