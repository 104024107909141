.dropdown-list {
    &__content {
        padding: $spacer--medium 0;
    }

    &--with-breakpoint {
        .dropdown-list__content {
            padding: 0;
        }
    }
}
