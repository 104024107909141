.blog {
    &__featured-posts {
        margin-top: 0;

        @include mq($screen-m) {
            margin-top: $spacer--large;
        }

        .section {
            margin-bottom: $spacer--extra-large;

            &__heading {
                @include mq($screen-m) {
                    margin: 0 0 $spacer--medium;
                }
            }
        }
    }

    &__nav-heading {
        padding: $spacer 0;
    }
}

.blog-post {
    margin-top: $spacer--large;
}
